@import "~/src/variables.scss";

#square_a1, #square_a3, #square_a5, #square_a7,
#square_b2, #square_b4, #square_b6, #square_b8,
#square_c1, #square_c3, #square_c5, #square_c7,
#square_d2, #square_d4, #square_d6, #square_d8,
#square_e1, #square_e3, #square_e5, #square_e7,
#square_f2, #square_f4, #square_f6, #square_f8,
#square_g1, #square_g3, #square_g5, #square_g7,
#square_h2, #square_h4, #square_h6, #square_h8 {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #{$primary};
  user-select: none;

  &:hover {
    box-shadow: inset 0 0 3px 3px #{$dark-primary};
  }
}

#square_a2, #square_a4, #square_a6, #square_a8,
#square_b1, #square_b3, #square_b5, #square_b7,
#square_c2, #square_c4, #square_c6, #square_c8,
#square_d1, #square_d3, #square_d5, #square_d7,
#square_e2, #square_e4, #square_e6, #square_e8,
#square_f1, #square_f3, #square_f5, #square_f7,
#square_g2, #square_g4, #square_g6, #square_g8,
#square_h1, #square_h3, #square_h5, #square_h7 {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #{$lite-primary};
  user-select: none;

  &:hover {
    box-shadow: inset 0 0 3px 3px #{$dark-primary};
  }
}

.active {
  box-shadow: inset 0 0 3px 3px #{$dark-primary};
}

.dummy:hover {
  box-shadow: none !important;
}

.figure_wrapper {

}

.figure {
  width: 100%;
  height: 100%;
  position: relative;
}

.available:after {
  content: '';
  height: 4%;
  width: 4.25%;
  position: absolute;
  border-radius: 50%;

  margin-top: 4.25%;
  margin-left: 4.25%;

  background-color: #{$dark-primary};
  opacity: 0.3;
}
