@import "~/src/variables.scss";

.item {
  display: flex;
  justify-content: space-between;
}

.item_info {
  display: flex;
  cursor: pointer;
}

.item_name {
  display: flex;
  margin-right: 5px;

  :hover {
    background-color: #{$dark-primary};
  }
}

#edit_variant,
#delete_variant {
  cursor: pointer;
}

.variant_color {
  width: 10px;
  height: 10px;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 5px;
  border-radius: 10px;
}
