@import "~/src/variables.scss";

.item {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.item_info {
  display: flex;
  cursor: pointer;
}

.item_name {
  display: flex;
  margin-right: 5px;

  :hover {
    background-color: #{$dark-primary};
  }
}

.item_result {
  padding-top: 3px;
  padding-right: 3px;
  font-size: 12px;
}

#change_item_button,
#delete_item_button {
  cursor: pointer;
}
