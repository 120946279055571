@import "./variables.scss";

html, body, #root {
  background-color: #{$background};
  color: #{$text};
  scrollbar-color: #{$primary} #{$background};
  scrollbar-width: thin;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal-backdrop {
  z-index: 10;
}
