@import "~/src/variables.scss";

#container {
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin: 60px 10px 10px 10px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 576px;
  }
}

.line {
  margin: 0px 0px 10px 0px;
  display: flex;
  flex-direction: column;
}
