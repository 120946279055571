@import "~/src/variables.scss";

.item {
  display: flex;
  justify-content: space-between;
}

.item_info {
  display: flex;
}

.item_name {
  display: flex;
  margin-right: 3px;
}

.item_active {
  cursor: pointer;

  :hover {
    background-color: #{$dark-primary};
  }
}

#add {
  cursor: pointer;
}
