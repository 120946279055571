@import "~/src/variables.scss";

.group_item {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
}

.group_item_info {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.group_name {
  display: flex;
  margin-right: 10px;

  :hover {
    background-color: #{$dark-primary};
  }
}

.group_item_button {
  cursor: pointer;
}

.technical_items {
  flex-direction: column;
  border-left: 2px solid #{$lite-primary};
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}
