@import "~/src/variables.scss";

.textarea {
  background-color: #{$dark-primary};
  color: #{$text};
  min-height: 100px;
  height: 100px;
}

.textarea:focus {
  background-color: #{$dark-primary};
  color: #{$text};
  box-shadow: 0 0 5px #{$lite-primary};
}

.textarea::placeholder {
    color: #{$text};
  opacity: 0.5;
}
