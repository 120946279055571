@import "~/src/variables.scss";

.board, .row, .square {
  display: flex;
}

.board {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.row {
  flex-direction: row;
  height: 100%;
}

.square {
  width: 100%;
}

#float_figure {
  position: absolute;
  user-select: none;
  width: 12.5%;
  height: 12.5%;
}

#float_figure_edit {
  position: absolute;
  user-select: none;
  width: 11.1%;
  height: 11.1%;
}

#extra_right {
  display: flex;
  flex-direction: column;
}

#extra_bottom {
  display: flex;
  flex-direction: row;
}

.extra {
  cursor: pointer;
  width: 100%;
  user-select: none;
}

.extra_active {
  border: solid 1px #{$lite-primary};
  border-radius: 3px;
}
