@import "~/src/variables.scss";

#container {
  display: flex;
  margin: auto;
  outline: none;
}

#form {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

#auth_login,
#auth_password {
  display: flex;
  flex-direction: column;
  margin: 10px auto 0px auto;
}

#auth_submit {
  display: flex;
  margin: 10px auto 0px auto;
}
