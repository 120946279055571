@import "~/src/variables.scss";

.move_header {
  font-size: 15px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

#flip_button {
  font-size: 15px;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}
.score {
  margin-left: 10px;
  background: #{$lite-primary};
  color: #{$dark-primary};
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
}
