@import "~/src/variables.scss";

.input,
.input:focus {
  background-color: #{$dark-primary} !important;
  color: #{$text} !important;
  border-radius: 6px;
  padding: 6px 10px 6px 10px;
}

.input:focus {
  box-shadow: 0 0 5px #{$lite-primary};
}

.input::placeholder {
  color: #{$text};
  opacity: 0.5;
}
