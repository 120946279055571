@import "~/src/variables.scss";

.wa8, .wa1, .bh8, .bh1 {
  margin-left: 0%;
}

.wb8, .wb1, .bg8, .bg1 {
  margin-left: 12.5%;
}

.wc8, .wc1, .bf8, .bf1 {
  margin-left: 25%;
}

.wd8, .wd1, .be8, .be1 {
  margin-left: 37.5%;
}

.we8, .we1, .bd8, .bd1 {
  margin-left: 50%;
}

.wf8, .wf1, .bc8, .bc1 {
  margin-left: 62.5%;
}

.wg8, .wg1, .bb8, .bb1 {
  margin-left: 75%;
}

.wh8, .wh1, .ba8, .ba1 {
  margin-left: 87.5%;
}

.wa1, .wb1, .wc1, .wd1, .we1, .wf1, .wg1, .wh1, .bh8, .bg8, .bf8, .be8, .bd8, .bc8, .bb8, .ba8 {
  margin-top: 50%;
}

#promotion {
  background-color: #{$lite-primary};
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 12.5%;

}

.img {
  border: solid 1px #{$dark-primary};
  cursor: pointer;
}

.img:hover {
  border: none;
  box-shadow: inset 0 0 3px 3px #{$dark-primary};
}
