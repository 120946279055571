@import "~/src/variables.scss";

.opening_side {
  padding-right: 4px;
}

.item {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
}

.item_info {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.item_name {
  display: flex;
  margin-right: 10px;

  :hover {
    background-color: #{$dark-primary};
  }
}

.item_buttons {
  cursor: pointer;
}

.variant_items {
  flex-direction: column;
  border-left: 2px solid #{$lite-primary};
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
}
