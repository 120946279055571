@import "~/src/variables.scss";

.offcanvas_header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #{$background};
  color: #{$text};
}

.offcanvas_body {
  overflow-y: auto !important;
  background-color: #{$background};
  color: #{$text};
  height: -webkit-fill-available;

  @include media-breakpoint-up(md) {
    ::-webkit-scrollbar {
      width: 10px;
      background-color: #{$background};
    }

    ::-webkit-scrollbar-thumb {
      background: #{$primary};
    }
  }
}

#exit_button {
  cursor: pointer;
}
