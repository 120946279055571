@import "~/src/variables.scss";

.menu_wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 8px;
  background-color: #{$dark-primary};
  border: solid 1px #{$lite-primary};
  border-radius: 3px;
  width: 144px;
}

.menu_item {
  display: flex;
  cursor: pointer;
  font-weight: normal;
  color: #{$text};
}

.menu_item:hover {
  background-color: #{$background};
}
