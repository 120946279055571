@import "~/src/variables.scss";

.move_header {
  font-size: 15px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

#analyze_button, #flip_button {
  margin-right: 10px;
  border-right: solid 1px #{$lite-primary};
  padding-right: 10px;
}

#analyze_button, #flip_button, #save_button {
  font-size: 15px;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

#engine_output {
  flex-direction: column;
}

.analyze_line,
.analyze_score,
.analyze_depth,
.analyze_moves {
  display: flex;
}

.analyze_line {
  flex-direction: row;
}

.analyze_score,
.analyze_depth,
.analyze_moves {
  margin-right: 10px;
  min-width: 50px;
}

.analyze_moves {
  flex-wrap: wrap;
}
