@import "~/src/variables.scss";

#topmenu {
  background-color: #{$primary};
  padding: 10px;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1;
  justify-content: space-between;
  height: 50px;
}

#navbar, #right_menu {
  display: flex;
  align-items: center;
}

.navbar_link {
  cursor: pointer;
  text-transform: uppercase;
  margin-right: 3px;
  margin-bottom: 0px;
  padding: 3px;
}

.active {
  background-color: #{$dark-primary};
  border-radius: 3px;
}

#user_login, #settings_button, #exit_button, #menu_button {
  margin-left: 10px;
}

#settings_button, #exit_button, #menu_button {
  font-size: 15px;
  color: #{$text};
  cursor: pointer;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    color: #{$text};
  }
}
