@import "~/src/variables.scss";

.empty_wrapper {
  display: contents;
}

.first_lines,
.lines {
  flex-direction: column;
  display: flex;
  margin-left: 10px;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-left: 20px;
  }
}

.first_lines {
  margin-left: 0px;
}

.line {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
}
