@import "~/src/variables.scss";

.select,
.select:focus,
.select:hover {
  background-color: #{$dark-primary};
  color: #{$text};
  border-radius: 6px;
  padding: 8px 10px 8px 10px;
}
