@import "~/src/variables.scss";

.move_header {
  font-size: 15px;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

#flip_button {
  font-size: 15px;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}
