@import "~/src/variables.scss";
@font-face {
  font-family: merifont;
  src:  url('../../../public/fonts/MERIFONT.TTF');
}

.move,
.move_number
.move_first_letter,
.move_tile {
  display: flex;
  color: #{$text}
}

.move {
  margin-right: 5px;
  cursor: pointer;
}

.move:hover {
  background-color: #{$dark-primary}
}

.move_active {
  background-color: #{$dark-primary}
}

.move_number {
  padding-right: 2px;
}

.move_first_letter {
  position: initial;
  vertical-align: bottom;
  font-family: merifont;
}

.move_main_line {
  font-weight: bold;
}

.move_comment {
  display: flex;
  color: #{$lite-primary};
  margin-right: 5px;
  flex-wrap: wrap;
  font-style: italic;
}
