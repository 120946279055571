@import "~/src/variables.scss";

#container {
  display: flex;
  margin: 60px 10px 10px 10px;
  width: 100%;
  max-width: -webkit-fill-available;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: center;
}

.typical {
  height: fit-content;
  margin: 10px;
  padding: 10px;
  border: solid 1px #{$primary};
  border-radius: 3px;
}

.typical_header {
  margin-bottom: 10px;
}

.typical_header_name {
  margin-right: 10px;
  cursor: pointer;
}

.board_wrapper {
  width: 324px;
  height: 324px;
}

#delete_typical, #edit_typical {
  cursor: pointer;
}
