@import "~/src/variables.scss";

#modal_content {
  background-color: #{$background};
  color: #{$text};
}

.input_container, .glyphs_container {
  display: flex;
  flex-direction: column;
  margin: 10px auto 0px auto;
}

.glyphs_container {
  flex-direction: row;
  flex-wrap: wrap;
}

.glyph {
  display: flex;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 5px;
  border: 1px solid #{$primary};
};

.glyph:hover {
  background-color: #{$dark-primary};
}

.glyph_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
