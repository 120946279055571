@import "~/src/variables.scss";

.ordinary, .small {
  color: #{$text};
  background-color: #{$dark-primary};
  border-color: #{$lite-primary};
}

.ordinary {
  font-size: 16px;
}

.small {
  font-size: 14px;
}

.ordinary:hover,
.ordinary:active,
.ordinary:visited,
.small:hover,
.small:active,
.small:visited {
  background-color: #{$primary};
}
