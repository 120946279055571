@import "~/src/variables.scss";

#container {
  display: flex;
  flex-direction: column;
  margin: 60px 0px 10px 10px;
  width: 100%;
  outline:none;
}

#board_moves {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

#board_buttons {
  display: flex;
  flex-direction: column;
  position: fixed;
  margin-right: 10px;

  @include media-breakpoint-up(md) {
    margin-right: 0px;
    position: relative;
  }
}

#board {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #{$background};
  width: calc(100vw - 20px);
  height: calc(100vw - 20px);
  max-width: 384px;
  max-height: 384px;

  @include media-breakpoint-up(md) {
    max-width: none;
    max-height: none;
    width: 384px;
    height: 384px;
    aspect-ratio: 1 / 1;
  }

  @include media-breakpoint-up(lg) {
    width: 450px;
    height: 450px;
  }

  @include media-breakpoint-up(xl) {
    width: 500px;
    height: 500px;
  }

  @include media-breakpoint-up(xxl) {
    width: 550px;
    height: 550px;
  }
}

#buttons {
  display: flex;
  margin: 10px auto 0px auto;

  @include media-breakpoint-up(md) {
    margin: 10px 0px 0px 0px;
  }
}

#moves {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  padding-right: 10px;
  height: min(calc(100vw + 14px), 418px);

  @include media-breakpoint-up(md) {
    align-self: baseline;
    margin: 0px 0px 0px 10px;
    height: 384px;
  }

  @include media-breakpoint-up(lg) {
    height: 450px;
  }

  @include media-breakpoint-up(xl) {
    height: 500px;
  }

  @include media-breakpoint-up(xxl) {
    height: 550px;
  }
}

#moves_header {
  display: flex;
}

#moves_pgn {
  display: flex;
  flex-wrap: wrap;
}

#footer {
  display: flex;
  overflow: auto;
  margin-top: 10px;
}

#moves_accordion {
  display: flex;
  margin: min(calc(100vw + 14px), 418px) 10px 10px 0px;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

#footer_accordion {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
