@import "~/node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "~/node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "~/node_modules/bootstrap/scss/functions";
@import "~/node_modules/bootstrap/scss/variables";
@import "~/node_modules/bootstrap/scss/mixins";

$primary: #00A3A3;
$lite-primary: #CCFFFF;
$dark-primary: #003333;
$background: #303030;
$text: #F0F0F0;
